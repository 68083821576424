import Footer from "../Footer";
import { LoginForm } from "@userfront/toolkit";

import "./Login.scss";
import NavBar from "../NavBar";

const Login = () => {
  return (
    <>
      <NavBar />
      <div className={`Login-ButtonView`}>
        {/* <div style={{ display: "flex" }}> */}
          <LoginForm />
        {/* </div> */}
      </div>

      <Footer />
    </>
  );
};

export default Login;
