import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Userfront, {
  SignupForm,
  LoginForm,
  LogoutButton,
} from "@userfront/toolkit";

import { ThemeContext } from "../../contexts/theme-context";

import Code from "../Code";
import Guitar from "../Guitar";
import Home from "../Home";
import Other from "../Other";
import AboutMe from "../Code/Views/AboutMe";
import AboutThisSite from "../Code/Views/AboutThisSite";
import GuitarVideoPlayer from "../Guitar/Views/GuitarVideoPlayer";
import NotFound from "../NotFound";
import WeatherFullPageView from "../Weather/Views/WeatherFullPageView";
import CalculatorVersionSelect from "../Other/Calculator/CalculatorVersionSelect";
import CalculatorV1 from "../Other/Calculator/v1";
import CalculatorV2 from "../Other/Calculator/v2";
import Blog from "../Code/Views/Blog";
import { useEffect, useState } from "react";
import Login from "../Login";

Userfront.init(`${process.env.REACT_APP_USERFRONT}`);

function RequireAuth({ children }: { children: any }) {
  let location = useLocation();

  if (!Userfront.tokens.accessToken) {
    // Redirect to the /login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

const App = () => {
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <Routes>
          <Route path="/" element={<Home theme={theme} />} />

          <Route path="/code" element={<Code />} />
          <Route path="/code/about-me" element={<AboutMe />} />
          <Route
            path="/code/about-this-site"
            element={<AboutThisSite theme={theme} />}
          />
          <Route path="/code/blog" element={<Blog />} />

          <Route
            path="/guitar"
            element={
              <RequireAuth>
                <Guitar theme={theme} />
              </RequireAuth>
            }
          />
          <Route
            path="/guitar/video-player"
            element={
              <RequireAuth>
                <GuitarVideoPlayer theme={theme} />
              </RequireAuth>
            }
          />

          <Route path="/login" element={<Login />} />

          <Route path="/other" element={<Other theme={theme} />} />
          <Route
            path="/other/calculator/version-select"
            element={<CalculatorVersionSelect theme={theme} />}
          />
          <Route
            path="/other/calculator/version-select/v1"
            element={<CalculatorV1 theme={theme} />}
          />
          <Route
            path="/other/calculator/version-select/v2"
            element={<CalculatorV2 theme={theme} />}
          />

          <Route path="/weather" element={<WeatherFullPageView />} />

          <Route path="*" element={<NotFound theme={theme} />} />
        </Routes>
      )}
    </ThemeContext.Consumer>
  );
};

export default App;
