import { Link } from "react-router-dom";
import Footer from "../Footer";
import NavBar from "../NavBar";

import "./Code.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/theme-context";

const Code = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <NavBar />

      <div className={`Code-LinkedIn `}>
        <a
          className={`Code-LinkedIn--${theme}`}
          href="https://www.linkedin.com/in/houston-breedlove/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
      </div>

      <div className={`Code-Center Code-Center--${theme}`}>
        <Link
          to="/code/about-me"
          className={`Code-Button Code-Button--${theme}`}
        >
          about me
        </Link>
        <Link
          to="/code/about-this-site"
          className={`Code-Button Code-Button--${theme} Code-Button-AboutThisSite`}
        >
          about this site
        </Link>
        {/* <Link
          to="/code/blog"
          className={`Code-Button Code-Button--${theme} Code-Button-AboutThisSite`}
        >
          blog
        </Link> */}
      </div>

      <Footer />
    </>
  );
};

export default Code;
