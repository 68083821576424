import Footer from "../../Footer";
import NavBar from "../../NavBar";
import SubNav from "../../NavBar/SubNav";
import { Link } from "react-router-dom";

import "../Other.scss";

interface Props {
  theme: string;
}

const CalculatorWrapper = ({ theme }: Props) => {
  return (
    <>
      <>
        <NavBar />
        <SubNav label="calculator" />
        <div className={`Other-Center Other-Center--${theme}`}>
          <Link
            to="/other/calculator/version-select/v1"
            className={`Other-Button Other-Button--${theme}`}
          >
            version 1
          </Link>
          <Link
            to="/other/calculator/version-select/v2"
            className={`Other-Button Other-Button--${theme}`}
          >
            version 2
          </Link>
        </div>
        <Footer />
      </>
    </>
  );
};

export default CalculatorWrapper;
