import Footer from "../Footer";
import HoustonBreedlove from "../HoustonBreedlove";
import NavBar from "../NavBar";

import "./Home.scss";

interface Props {
  theme: string;
}

const Home = ({ theme }: Props) => (
  <>
    <NavBar />
    <div className={`Home-Center Home-Center--${theme}`}>
      <HoustonBreedlove />
    </div>
    <Footer />
  </>
);

export default Home;
