import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faCloud,
  faCloudRain,
  faSnowflake,
  faSun,
  faCloudSun,
  faMoon,
  faCloudMoon,
} from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

const ClearSky = "clear skies";
const MainlyClear = "mainly clear";
const PartlyCloudy = "partly cloudy";
const Overcast = "overcast";
const Fog = "fog";
const Drizzle = "drizzle";
const SlightRain = "slight rain";
const ModerateRain = "moderate rain";
const HeavyRain = "heavy rain";
const FreezingRain = "freezing rain";
const SlightSnowFall = "slight snow fall";
const ModerateSnowFall = "moderate snow fall";
const HeavySnowFall = "heavy snow fall";
const SlightRainShowers = "slight rain showers";
const ViolentRainShowers = "violent rain showers";
const HeavyRainShowers = "heavy rain showers";
const Thunderstorm = "stormy";

const TranslateWeatherCodeToString = (code: number) => {
  if (isNaN(code)) {
    return `Input must be of type Number but was provided typeof "${typeof code}"`;
  }
  switch (code) {
    case 0:
      return ClearSky;

    case 1:
      return MainlyClear;

    case 2:
      return PartlyCloudy;

    case 3:
      return Overcast;

    case 45:
      return Fog;

    case 48:
      return Fog;

    case 51:
      return Drizzle;

    case 53:
      return Drizzle;

    case 55:
      return Drizzle;

    case 56:
      return Drizzle;

    case 57:
      return Drizzle;

    case 61:
      return SlightRain;

    case 63:
      return ModerateRain;

    case 65:
      return HeavyRain;

    case 66:
      return FreezingRain;

    case 67:
      return FreezingRain;

    case 71:
      return SlightSnowFall;

    case 77:
      return SlightSnowFall;

    case 85:
      return SlightSnowFall;

    case 73:
      return ModerateSnowFall;

    case 75:
      return HeavySnowFall;

    case 86:
      return HeavySnowFall;

    case 80:
      return SlightRainShowers;

    case 81:
      return ModerateSnowFall;

    case 82:
      return ViolentRainShowers;

    case 95:
      return Thunderstorm;

    case 96:
      return Thunderstorm;

    case 99:
      return Thunderstorm;

    default:
      return "Unknown code";
  }
};

interface ITranslatedWeatherCodeToIcon {
  code?: number;
  size?: SizeProp;
  sunIsUp: boolean;
}

const TranslateWeatherCodeToIcon = ({
  code = 0,
  size = "xl",
  sunIsUp
}: ITranslatedWeatherCodeToIcon) => {
  if (isNaN(code)) {
    return `Input must be of type Number but was provided typeof "${typeof code}"`;
  }

  const Sun = () => (
    <FontAwesomeIcon className="Weather-Sun" icon={faSun} size={size} />
  );
  const Moon = () => <FontAwesomeIcon className="Weather-Moon" icon={faMoon} size={size} />;
  const MoonAndClouds = () => <FontAwesomeIcon icon={faCloudMoon} size={size} />;
  const SunAndClouds = () => <FontAwesomeIcon icon={faCloudSun} size={size} />;
  const Clouds = () => <FontAwesomeIcon icon={faCloud} size={size} />;
  const Rain = () => <FontAwesomeIcon icon={faCloudRain} size={size} />;
  const Snow = () => <FontAwesomeIcon icon={faSnowflake} size={size} />;
  const LightningBolt = () => <FontAwesomeIcon icon={faBolt} size={size} />;

  switch (code) {
    case 0:
      return sunIsUp ? Sun() : Moon();

    case 1:
      return sunIsUp ? Sun() : Moon();

    case 2:
      return sunIsUp ? SunAndClouds() : MoonAndClouds();

    case 3:
      return Clouds();

    case 45:
      return Clouds();

    case 48:
      return Clouds();

    case 51:
      return Rain();

    case 53:
      return Rain();

    case 55:
      return Rain();

    case 56:
      return Rain();

    case 57:
      return Rain();

    case 61:
      return Rain();

    case 63:
      return Rain();

    case 65:
      return Rain();

    case 66:
      return Snow();

    case 67:
      return Snow();

    case 71:
      return Snow();

    case 77:
      return Snow();

    case 85:
      return Snow();

    case 73:
      return Snow();

    case 75:
      return Snow();

    case 86:
      return Snow();

    case 80:
      return Rain();

    case 81:
      return Snow();

    case 82:
      return LightningBolt();

    case 95:
      return LightningBolt();

    case 96:
      return LightningBolt();

    case 99:
      return LightningBolt();

    default:
      return <></>;
  }
};

export { TranslateWeatherCodeToString, TranslateWeatherCodeToIcon };
