import geocode from "../../api/geocode/geocode";
import getWeather from "../../api/weather/weather";

const getLocationAndWeather = async (input: string) => {
  try {
    const geocodedData = await geocode(input);

    if (!geocodedData.results?.length) {
      throw new Error("No results from geocode API call.");
    }

    const { lat, lng } = geocodedData.results[0].geometry.location;
    const weatherData = await getWeather({ latitude: lat, longitude: lng });

    return {
      location: geocodedData.results[0].formatted_address,
      weather: weatherData,
    };
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error fetching data: ${error.message}`);
    } else {
      throw new Error(`Error fetching data: ${String(error)}`);
    }
  }
};

export default getLocationAndWeather;
