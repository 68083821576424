import { useContext, useState } from "react";

import { ThemeContext } from "../../../../contexts/theme-context";
import Footer from "../../../Footer";
import NavBar from "../../../NavBar";
import SubNav from "../../../NavBar/SubNav";
import ProgressBar from "../../../ProgressBar";
import BriefOverview from "./Sections/BriefOverview";
import TechnicalSkills from "./Sections/TechnicalSkills";
import MyWhy from "./Sections/MyWhy";

import "./AboutMe.scss";

const AboutMe = () => {
  const { theme } = useContext(ThemeContext);
  const [scrolled, setScrolled] = useState(0);

  const onScroll = () => {
    const { clientHeight, scrollHeight, scrollTop } =
      document.getElementById("AboutMe-Main") || {};

    const height =
      scrollHeight && clientHeight ? scrollHeight - clientHeight : 0;
    const scrolled = (scrollTop ? scrollTop / height : 0) * 100;

    setScrolled(scrolled);
  };

  return (
    <>
      <NavBar />
      <SubNav label="about me" />
      <ProgressBar percent={scrolled} />

      <main
        id="AboutMe-Main"
        className={`AboutMe-Center AboutMe-Center--${theme}`}
        onScroll={onScroll}
      >
        <p className="AboutMe-Center-IntroText">
          Welcome to my website, and first and foremost, I want to thank you for
          taking the time to visit and get to know me a little better! &#128513;
        </p>

        <BriefOverview />
        <TechnicalSkills />
        <MyWhy />
      </main>
      <Footer />
    </>
  );
};

export default AboutMe;
