import { useContext } from "react";

import LinkOut from "../../../../LinkOut";
import { ThemeContext } from "../../../../../contexts/theme-context";

import "../AboutMe.scss";

const TechnicalSkills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <div className="AboutMe-SectionBreak-Wrapper">
        <hr
          className={`AboutMe-SectionBreak1 AboutMe-SectionBreak1--${theme}`}
        />
        <p>technical skills</p>
        <hr
          className={`AboutMe-SectionBreak2 AboutMe-SectionBreak2--${theme}`}
        />
      </div>

      <p>
        As I'm primarily a{" "}
        <a
          className={`AboutMe-Link AboutMe-Link--${theme}`}
          href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
          target="_blank"
          rel="noreferrer"
        >
          JavaScript
        </a>{" "}
        developer, I feel the most at home in{" "}
        <LinkOut label="Node.js" to="https://nodejs.org/en" /> projects. That
        being said, I'm also a professional mobile developer who regularly
        releases features and updates to both{" "}
        <LinkOut label="Android" to="https://www.android.com/" /> and{" "}
        <LinkOut label="iOS" to="https://www.apple.com/ios/" /> platforms. If
        you're unfamiliar, those are 2 other completely different types of
        projects. However, through the use of{" "}
        <LinkOut label="React Native" to="https://reactnative.dev/" />, I'm able
        to work with a framework I'm already very comfortable with:{" "}
        <LinkOut label="React" to="https://react.dev/" />. I work well in{" "}
        <LinkOut label="Express.js" to="https://expressjs.com/" /> API projects,
        understand how to securely authenticate users in a frontend application,
        and know how to leverage the browser's or device's local storage
        mechanisms to create more performant solutions. I'm also highly
        comfortable with <LinkOut label="Redux" to="https://redux.js.org/" />{" "}
        and capable of leveraging{" "}
        <LinkOut
          label="SOLID"
          to="https://www.freecodecamp.org/news/solid-design-principles-in-software-development/"
        />{" "}
        design principles to help organize code in a scalable and logical way.
        I'm also fortunate to work for a company that prioritizes making its
        applications accessible, internationlized, and fully tested. Lastly, I
        simply enjoy building things that people enjoy looking at or interacting
        with.
      </p>
      <p>
        Basically, I'm a professional frontend software engineer, and I
        primarily professionally work in the mobile ecosystem, but at my core,
        I'm simply a problem solver! (If you've made it this far, please know, I
        appreciate you.)
      </p>
    </>
  );
};

export default TechnicalSkills;
