import "./ProblemToSolve.scss";

interface Props {
  operation?: string;
  showEqualSign?: boolean;
  showSquareRoot: boolean;
  theme: string;
  value1?: string;
  value2?: string;
}

const ProblemToSolve = ({
  operation,
  showEqualSign,
  showSquareRoot,
  theme,
  value1,
  value2,
}: Props) => {
  return (
    <div className={`ProblemToSolve ProblemToSolve--${theme}`}>
      {showSquareRoot && <p>√(</p>}
      {value1 && <p>{value1}</p>}
      {operation && <p>{operation === "√" ? "" : operation}</p>}
      {value2 && <p>{value2}</p>}
      {showSquareRoot && <p>)</p>}
      {showEqualSign ? <p>=</p> : " "}
    </div>
  );
};

export default ProblemToSolve;
