import Footer from "../../../Footer";
import NavBar from "../../../NavBar";

import "./AboutThisSite.scss";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../../../ProgressBar";
import SubNav from "../../../NavBar/SubNav";
import LinkOut from "../../../LinkOut";

interface Props {
  theme: string;
}

const AboutThisSite = ({ theme }: Props) => {
  const [designExpanded, setDesignExpanded] = useState(false);
  const [apiExpanded, setApiExpanded] = useState(false);
  const [logicExpanded, setLogicExpanded] = useState(false);
  const [scrolled, setScrolled] = useState(0);

  useEffect(() => {
    onScroll();
  }, [designExpanded, apiExpanded, logicExpanded]);

  const onScroll = () => {
    const element = document.getElementById("AboutThisSite-Main");

    const container = element?.scrollTop;
    const scrollHeight = element?.scrollHeight;
    const clientHeight = element?.clientHeight;
    const height =
      scrollHeight && clientHeight ? scrollHeight - clientHeight : 0;
    const scrolled = (container ? container / height : 0) * 100;

    setScrolled(scrolled);
  };

  return (
    <>
      <NavBar />
      <SubNav label="about this site" />
      <ProgressBar percent={scrolled} />
      <main
        id="AboutThisSite-Main"
        className={`AboutThisSite-Center AboutThisSite-Center--${theme}`}
        onScroll={onScroll}
      >
        {/* <div className="AboutThisSite-SectionBreak-Wrapper">
          <hr
            className={`AboutThisSite-SectionBreak1 AboutMe-SectionBreak1--${theme}`}
          />
          <p>disclaimer</p>
          <hr
            className={`AboutThisSite-SectionBreak2 AboutMe-SectionBreak2--${theme}`}
          />
        </div>

        <p className="AboutThisSite-Text AboutThisSite-Text-Disclaimer">
          While this will be the first version of my website for YOU, for me...
          This is something like version 100 (or 1000) or thereabouts (I lost
          count). A lot of time and effort went into the technical aspects, of
          course, but also into the decision to attempt to truly communicate
          through my new platform. Thank you for taking the time to witness what
          I've built so far. I'm excited to add to it and show you more of what
          I can do!
        </p> */}

        <div className="AboutThisSite-SectionBreak-Wrapper">
          <hr
            className={`AboutThisSite-SectionBreak1 AboutThisSite-SectionBreak1--${theme}`}
          />
          <p>brief overview</p>
          <hr
            className={`AboutThisSite-SectionBreak2 AboutThisSite-SectionBreak2--${theme}`}
          />
        </div>

        <p className="AboutThisSite-Text">
          1.{" "}
          <a className={`AboutThisSite-Link--${theme}`} href="#WhereHowWhat">
            The code repository for this site is located on GitHub, hosted by
            AWS Amplify, and built using React. I chose these technologies for
            good reason.
          </a>
        </p>
        <p className="AboutThisSite-Text">
          2.{" "}
          <a
            className={`AboutThisSite-Link--${theme}`}
            href="#MVPFunctionalities"
          >
            I knew when I started this project that there were a few
            functionalities I wanted my simple little site to have:
          </a>
        </p>
        <ul className="AboutThisSite-Text-List">
          <li>Should have a simplistic light a dark theme.</li>
          <li>Should communicate with an API of some kind.</li>
          <li>
            Should have some specific and thoughtful logic applied to something,
            not utilizing an "out of the box" solution.
          </li>
        </ul>
        <p className="AboutThisSite-Text">
          3.{" "}
          <a className={`AboutThisSite-Link--${theme}`} href="#OtherCoolThings">
            Other cool things worth mentioning and next steps.
          </a>
        </p>

        <div id="WhereHowWhat" className="AboutThisSite-SectionBreak-Wrapper">
          <hr
            className={`AboutThisSite-SectionBreak1 AboutThisSite-SectionBreak1--${theme}`}
          />
          <p>where / how / what</p>
          <hr
            className={`AboutThisSite-SectionBreak2 AboutThisSite-SectionBreak2--${theme}`}
          />
        </div>

        <p className="AboutThisSite-Text">
          The code repository for this site is on{" "}
          <LinkOut label="Github" to="https://github.com/" /> and the site
          itself is hosted via{" "}
          <LinkOut label="AWS Amplify" to="https://aws.amazon.com/amplify/" />.
          Github has always an easy choice for me. It's what I've consistently
          used, what every company I've worked for has used, what my colleagues
          are most familiar with, and it's simply a great tool overall in my
          opinion.
        </p>

        <p className="AboutThisSite-Text">
          It's been a while since I first deployed this site, but today it still
          works with that initial implementation. All I had to do was
          essentially create a 'new app' in the AWS Amplify console and then
          connect my GitHub code repository to AWS Amplify via a webhook. For
          me, this meant copying and pasting a URL into the settings of my
          GitHub account. Today, it seems to be even easier. It's on my 'to-do'
          list, but apparently, all you need to do now is provide access to your
          GitHub repo to AWS and then give the repo link to AWS Amplify. It's
          kind of the reverse process, but you can start and finish everything
          within the AWS console.
        </p>

        <p className="AboutThisSite-Text" style={{ fontStyle: "italic" }}>
          "So then why AWS Amplify?"
        </p>

        <p className="AboutThisSite-Text">
          To be honest, I was pleasantly surprised by how easy it was to use. My
          original plan all along was to create a React app and host it on AWS.
          In my opinion, AWS is the current leader in terms of cloud hosting
          solutions (and more) and is trusted by countless enterprise-level
          organizations. For my simple personal site, I'm sure it will suffice.
        </p>

        <p className="AboutThisSite-Text">
          <LinkOut label="React" to="https://react.dev/" /> was also an easy
          choice. When I made the career shift to software engineering, React
          was beginning to emerge as the leading framework of choice for most
          JavaScript applications worldwide. It's what I studied before my
          coding bootcamp (I also studied Vue and Angular), it's what was taught
          in the coding bootcamp, it's what the first product I worked on
          professionally used, and it's still one of the leading frameworks
          today. I spend most of my professional life writing code using the{" "}
          <LinkOut label="React Native" to="https://reactnative.dev/" />{" "}
          framework, which, if you're not already aware, is essentially the
          mobile version of React. Before becoming a mobile engineer, I spent
          most of my frontend time using React. Since I don't get to spend every
          day of my professional life using React itself, I wanted to keep my
          knowledge up to date, and I thought one way to do so was by
          maintaining this site.
        </p>

        <div
          id="MVPFunctionalities"
          className="AboutThisSite-SectionBreak-Wrapper"
        >
          <hr
            className={`AboutThisSite-SectionBreak1 AboutThisSite-SectionBreak1--${theme}`}
          />
          <p>MVP functionalities</p>
          <hr
            className={`AboutThisSite-SectionBreak2 AboutThisSite-SectionBreak2--${theme}`}
          />
        </div>

        <p className="AboutThisSite-Text">
          In my personal opinion... The minimum a portfolio site needs is the
          following:
        </p>
        <ul className="AboutThisSite-Text-List">
          <li>Should have a simplistic light a dark theme.</li>
          <li>Should communicate with an API of some kind.</li>
          <li>
            Should have some specific and thoughtful logic applied to something,
            not utilizing an "out of the box" solution.
          </li>
        </ul>
        <button
          className="AboutThisSite-Text AboutThisSite-DesignExpandButton"
          onClick={() => setDesignExpanded(!designExpanded)}
          style={{ fontWeight: "bold", marginTop: "1rem" }}
        >
          <FontAwesomeIcon
            className={`AboutThisSite-Expand AboutThisSite-Expand${
              designExpanded ? "--expanded" : "--closed"
            } AboutThisSite-Expand--${theme}`}
            icon={designExpanded ? faCircleMinus : faCirclePlus}
            size="sm"
          />{" "}
          Design / Theme
        </button>

        <div
          className={`AboutThisSite-Design AboutThisSite-Design${
            !designExpanded && "--closed"
          }`}
        >
          <p className="AboutThisSite-Text">
            I knew I wanted v1 of the site to be as simple as possible. When you
            arrive at houstonbreedlove.com, the first thing you see is 'Houston
            Breedlove.' Boom! You should feel pretty confident you've landed in
            the right spot, right? Up to this moment, I'm not 100% sure I love
            the color choices I made. They will likely change in the near
            future, but I made a decision and went with it (progress over
            perfection). Ultimately, we always have a navigation bar (and a
            sub-navigation bar when appropriate), a middle content area, and a
            footer. Essentially, it's a blank slate!
          </p>

          <p className="AboutThisSite-Text">
            In the top right of the page, there is a light and dark theme
            toggle. The toggle itself is essentially a{" "}
            <LinkOut
              label="label element"
              to="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label"
            />{" "}
            with a moon and sun on the left and right. A white ball sits over
            it, and based on the{" "}
            <LinkOut
              label="React state"
              to="https://react.dev/reference/react/useState"
            />
            , the ball transitions to the left or right side of the label, thus
            hiding one of the icons.
          </p>

          <p className="AboutThisSite-Text" style={{ fontStyle: "italic" }}>
            "Okay. Cool button, but how does it actually work?"
          </p>

          <p className="AboutThisSite-Text">
            Before setting the theme itself, I had to consider which theme I
            would show first by default. Instead of just going with my opinion,
            I decided that "if the user has not touched the theme toggle before,
            I'll get the user's preferred theme from the browser." So if you use
            Chrome, for example, and have a dark theme selected, I will set the
            theme to dark first. If I can't find the browser's preferred theme,
            I default to the 'light' theme. If the user ends up interacting with
            the theme toggle, I ignore the browser's preference and save the
            user's last selected theme to the browser's local storage. So if the
            user's default browser theme is dark, but then they prefer my light
            theme, if they leave and ever return to my website, the browser will
            remember they preferred that theme for my site! I then set the value
            'light' or 'dark' to a{" "}
            <LinkOut
              label="React context"
              to="https://react.dev/learn/passing-data-deeply-with-context"
            />{" "}
            and can provide the value anywhere in my app. So what I hope this
            communicates is "I can style a page conditionally."
          </p>
        </div>

        <button
          className="AboutThisSite-Text AboutThisSite-DesignExpandButton"
          onClick={() => setApiExpanded(!apiExpanded)}
          style={{ fontWeight: "bold", marginTop: "1rem" }}
        >
          <FontAwesomeIcon
            className={`AboutThisSite-Expand AboutThisSite-Expand${
              apiExpanded ? "--expanded" : "--closed"
            } AboutThisSite-Expand--${theme}`}
            icon={apiExpanded ? faCircleMinus : faCirclePlus}
            size="sm"
          />{" "}
          API
        </button>

        <div
          className={`AboutThisSite-API AboutThisSite-API${
            !apiExpanded && "--closed"
          }`}
        >
          <p className="AboutThisSite-Text">
            I wanted to maintain the theme of 'simplicity' in my API selection.
            I also wanted it to provide some value. Unfortunately, this meant
            ruling out the <LinkOut label="PokeAPI" to="https://pokeapi.co/" />,
            which is one of my personal favorite go-to's for fun design side
            projects. Instead, I chose the weather API and went with{" "}
            <LinkOut label="Open-Meteo" to="https://open-meteo.com/" /> because
            it was free, and I didn't want to worry about securely storing any
            API keys for v1 of the site.
          </p>

          <p className="AboutThisSite-Text">
            If you haven't found it yet, the weather component of the site is
            located at the bottom right of the footer. No matter where you view
            my site from, you will see the current weather in Raleigh, North
            Carolina. This is the weather where I am! My main goal was to
            establish a connection with a data source outside of my site and
            display the data. I can retrieve a lot of useful data from the API,
            and I intend for this to be just a starting point.
          </p>
        </div>

        <button
          className="AboutThisSite-Text AboutThisSite-DesignExpandButton"
          onClick={() => setLogicExpanded(!logicExpanded)}
          style={{ fontWeight: "bold", marginTop: "1rem" }}
        >
          <FontAwesomeIcon
            className={`AboutThisSite-Expand AboutThisSite-Expand${
              logicExpanded ? "--expanded" : "--closed"
            } AboutThisSite-Expand--${theme}`}
            icon={logicExpanded ? faCircleMinus : faCirclePlus}
            size="sm"
          />{" "}
          Logic
        </button>

        <div
          className={`AboutThisSite-Design AboutThisSite-Design${
            !logicExpanded && "--closed"
          }`}
        >
          <p className="AboutThisSite-Text">
            I wanted to demonstrate that I can use code to implement purposeful
            logic, and I thought a calculator would be a great starting point.
            Plus, I enjoy math and would take pride in using a calculator I
            designed whenever I needed it. I felt that something like this was
            necessary because it required a slightly different mindset than what
            I wanted to showcase with the weather API functionality, which was
            essentially 'retrieve data from somewhere and display it in a
            pleasing manner.' A calculator involves various elements a user can
            interact with: numbers, operations, inversion, and reset. When the
            user interacts with these elements, we display the input in a
            user-friendly manner and provide a utility for the user to achieve a
            desired outcome, such as solving a math equation.
          </p>

          <p className="AboutThisSite-Text">
            There were several small considerations that I hadn't thought of
            until I delved into it, and they presented interesting challenges.
            For example, the calculator should have both a light and dark theme,
            of course. It should also be usable in both landscape and portrait
            views. Sometimes math equations can produce very long numbers, so I
            had to decide whether to cut off numbers or round them up. I opted
            for displaying as much as I could before ellipsizing the end. As you
            dive into the logic of a calculator, you quickly realize the
            importance of handling the user flow, such as entering the first
            operand, then the operator ('What if the user changes their mind and
            chooses a different operator?'), and then the second operand. Do you
            show the equals sign (=) because the user just pressed the '='
            button, and does it actually offer any value? These are just some of
            the questions that I had to address.
          </p>

          <p className="AboutThisSite-Text">
            There is still work to be done, and I already see several other ways
            I could have written that code. I intend to make changes in the near
            future. However, I'm satisfied with v1 and believe it accomplishes
            and communicates what I intended with what is currently in place.
          </p>
        </div>

        <div
          id="OtherCoolThings"
          className="AboutThisSite-SectionBreak-Wrapper"
        >
          <hr
            className={`AboutThisSite-SectionBreak1 AboutThisSite-SectionBreak1--${theme}`}
          />
          <p>other cool things</p>
          <hr
            className={`AboutThisSite-SectionBreak2 AboutThisSite-SectionBreak2--${theme}`}
          />
        </div>

        <p className="AboutThisSite-Text">
          Did you notice the scroll progress bar at the top that has been
          increasing across the screen as you scroll down the page? I thought it
          was really cool, and it became a brief side quest while working on
          this page.
        </p>

        <p className="AboutThisSite-Text">
          Did you notice that you can click the links in the 'brief overview'
          section, and they will quickly take you to that section of the page? I
          thought it's handy, especially for longer posts like this.
        </p>

        <p className="AboutThisSite-Text">
          Did you notice that you can expand the sections within the 'MVP
          functionalities' section (Design / Theme, API, Logic)?
        </p>

        <p className="AboutThisSite-Text">
          The above features certainly weren't completely necessary, but they
          are just little ways I thought could make this page feel a bit more
          helpful. I also built most of them myself. While working on them, I
          also learned something new. Did you know you can add emoticons using
          HTML codes? &#128526;
        </p>
      </main>
      <Footer />
    </>
  );
};

export default AboutThisSite;
