import axios from "axios";

import { IWeatherResponse } from "./types";

const parseResult = (result: IWeatherResponse) => ({
  data: {
    current_weather: result.current_weather,
    daily: {
      sunrise: result.daily.sunrise,
      sunset: result.daily.sunset,
      temperature: result.daily.temperature_2m_max?.slice(0, 7),
      time: result.daily.time.slice(0, 7),
      weathercode: result.daily.weathercode,
    },
    hourly: {
      temperature: result.hourly.temperature_2m?.slice(0, 25),
      time: result.hourly.time.slice(0, 25),
      weathercode:
        result.hourly.weathercode && Array.isArray(result.hourly.weathercode)
          ? result.hourly.weathercode?.slice(0, 25)
          : result.hourly.weathercode,
    },
  },
});

const getWeather = ({
  latitude,
  longitude,
}: {
  latitude: string;
  longitude: string;
}) => {
  return axios
    .get("https://api.open-meteo.com/v1/forecast", {
      params: {
        current_weather: true,
        daily: ["sunrise", "sunset", "temperature_2m_max", "weathercode"],
        hourly: ["temperature_2m", "weathercode"],
        latitude,
        longitude,
        temperature_unit: "fahrenheit",
        timezone: "auto",
      },
    })
    .then(({ data }) => {
      return parseResult(data);
    })
    .catch((error) => {
      console.error("api/getWeather error: ", error);

      return {
        data: null,
        error: true,
        error_message: `api/getWeather Error: ${error}`,
      };
    });
};

export default getWeather;
