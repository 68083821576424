import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ThemeContext } from "../../../../contexts/theme-context";

import { getReverseGeoCode, getWeather } from "../../WeatherApi";
import { TranslateWeatherCodeToString } from "../../WeatherInterpretationCodes";
import WeatherIcon from "../../WeatherIcon";

import { dailyLabelMap, hourlyLabelArray } from "./constants";
import { ILocation, IWeatherData } from "./types";

import "./WeatherFullPageView.scss";
import CurrentWeather from "./components/CurrentWeather";
import HourlyItem from "./components/HourlyItem";
import WeatherInBlank from "./components/WeatherInBlank";

export const labelToTimeTranslations = hourlyLabelArray.map((hour, index) => {
  const time = new Date().setHours(index, 0, 0, 0);
  return { label: hour, time: new Date(time) };
});

const WeatherFullPageView = () => {
  const raleighCoordinates = {
    latitude: 35.7796,
    longitude: -78.6382,
  };

  const { theme } = useContext(ThemeContext);

  const [error, setError] = useState(false);
  const [loadingWeather, setLoadingWeather] = useState(false);

  const [loadingReverseGeoCode, setLoadingReverseGeoCode] = useState(false);
  const [location, setLocation] = useState(raleighCoordinates);

  const [reverseGeoCode, setReverseGeoCode] = useState("");
  const [weather, setWeather] = useState<IWeatherData | null>();
  const [translatedWeatherCode, setTranslatedWeatherCode] = useState("");

  const [isSunUp, setIsSunUp] = useState(false);

  useEffect(() => {
    const sunHasRisen = () => {
      const currentTime = new Date().toISOString();

      if (
        weather?.daily.sunrise &&
        weather?.daily.sunset &&
        currentTime > new Date(weather?.daily.sunrise[0]).toISOString() &&
        currentTime < new Date(weather?.daily.sunset[0]).toISOString()
      )
        return true;

      return false;
    };

    setIsSunUp(sunHasRisen());
  }, [weather]);

  useEffect(() => {
    if (location) {
      handleGetWeather(location);
      handleReverseGeocode(location);
    }
  }, [location]);

  const navigate = useNavigate();

  const handleGetWeather = async (location: ILocation) => {
    setLoadingWeather(true);

    const { data, error } = await getWeather(location);

    if (error) {
      setError(true);
      setLoadingWeather(false);
      setWeather(null);
      setTranslatedWeatherCode("");
    }

    if (data) {
      const translatedWeatherCode = TranslateWeatherCodeToString(
        data.current_weather.weathercode
      );

      setLoadingWeather(false);
      setWeather(data);
      setTranslatedWeatherCode(translatedWeatherCode);
    }
  };

  const handleReverseGeocode = async (location: ILocation) => {
    setLoadingReverseGeoCode(true);
    const { data, error } = await getReverseGeoCode(location);

    if (error) {
      setError(true);
      setLoadingReverseGeoCode(false);
      setReverseGeoCode("");
    }

    if (data) {
      setLoadingReverseGeoCode(false);
      setReverseGeoCode(data.city);
    }
  };

  return (
    <div className={`WeatherFullPageView WeatherFullPageView--${theme}`}>
      <div className="WeatherFullPageView-Nav">
        <button
          className="WeatherFullPageView-Back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="2x" />
        </button>
        <div className="WeatherFullPageView-h1">
          <h1>Weather in {reverseGeoCode}</h1>
          {/* <WeatherInBlank location={reverseGeoCode} onLocationPress={() => console.log("pressed!")} /> */}
        </div>
      </div>
      <div className="WeatherFullPageView-ContentWrapper">
        <CurrentWeather weather={weather || undefined} />
        <section className="WeatherFullPageView-Hourly">
          <ul>
            {weather?.hourly.time.map((time, index) => {
              return (
                <div key={index}>
                  <HourlyItem
                    label={hourlyLabelArray[index]}
                    sunrise={weather.daily.sunrise[0]}
                    sunset={weather.daily.sunset[0]}
                    temperature={weather.hourly.temperature[index]}
                    weatherCode={weather?.hourly.weathercode[index]}
                  />
                </div>
              );
            })}
          </ul>
        </section>
        <section className="WeatherFullPageView-Weekly">
          <ul>
            {weather?.daily.time.map((time, index) => {
              return (
                <li className="WeatherFullPageView-Weekly-ListItem" key={time}>
                  <WeatherIcon
                    code={weather?.daily.weathercode[index]}
                    size="2x"
                    sunIsUp={true}
                  />
                  <div className="WeatherFullPageView-Weekly-ListItem-TextWrapper">
                    <p className="WeatherFullPageView-Hourly-ListItem-Time">
                      {dailyLabelMap[new Date(`${time} 00:00:00`).getDay()]}
                    </p>

                    <p className="WeatherFullPageView-Weekly-ListItem-Temperature">
                      {weather &&
                        `${Math.round(weather?.daily.temperature[index])}°F`}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default WeatherFullPageView;
