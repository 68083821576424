import Weather from "../Weather";

import "./Footer.scss";

const Footer = () => {
  return (
    <footer className={`Footer`}>
      <Weather />
    </footer>
  );
};

export default Footer;
