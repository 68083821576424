import React from "react";

import "./InstagramIcon.scss";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  style?: object;
  theme: string;
}

const InstagramIcon = ({ style, theme }: Props) => (
  <div className={`InstagramIcon`} style={style}>
    <a
      className={`InstagramIcon--${theme}`}
      href="https://www.instagram.com/hcbreedlove/?hl=en"
      target="_blank"
      rel="noreferrer"
    >
      <FontAwesomeIcon icon={faInstagram} size="2x" />
    </a>
  </div>
);

export default InstagramIcon;
