import { useContext, useState } from "react";

import { ThemeContext } from "../../../../contexts/theme-context";
import Footer from "../../../Footer";
import NavBar from "../../../NavBar";
import SubNav from "../../../NavBar/SubNav";
import ProgressBar from "../../../ProgressBar";

import "./Blog.scss";
import { Link } from "react-router-dom";

const Blog = () => {
  const { theme } = useContext(ThemeContext);
  const [scrolled, setScrolled] = useState(0);

  const onScroll = () => {
    const { clientHeight, scrollHeight, scrollTop } =
      document.getElementById("Blog-Main") || {};

    const height =
      scrollHeight && clientHeight ? scrollHeight - clientHeight : 0;
    const scrolled = (scrollTop ? scrollTop / height : 0) * 100;

    setScrolled(scrolled);
  };

  return (
    <>
      <NavBar />
      <SubNav label="blog" />
      <ProgressBar percent={scrolled} />

      <main
        id="Blog-Main"
        className={`Blog-Center Blog-Center--${theme}`}
        onScroll={onScroll}
      >
        <Link
          to="/code/about-this-site"
          className={`Blog-Post Blog-Post--${theme}`}
        >
          first post
        </Link>
      </main>
      <Footer />
    </>
  );
};

export default Blog;
