import axios from "axios";
import mockGeocodeResponse from "./mockGeocodeResponse";

const geocode = (input: string) => {
  if (!input) {
    throw new Error("No input provided");
  }
  const apiKey = process.env.REACT_APP_GEOCODE;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${input}&key=${apiKey}`;

  if (process.env.NODE_ENV === "development") {
    console.log("development returning mock response for geocode");
    return mockGeocodeResponse;
  }

  return axios
    .get(url)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Error fetching geocode data");
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`Error fetching geocode data: ${error.message}`);
    });
};

export default geocode;
