import { useState } from "react";

// import Particles from "react-particles";
// import type { Engine } from "tsparticles-engine";
// import { loadLinksPreset } from "tsparticles-preset-links";

import { ThemeContext } from "../../contexts/theme-context";
import Routes from "../Routes";

import "./App.scss";

const App = () => {
  const getCurrentTheme = () => {
    // if user previously interactedd with the theme toggle,
    // user the last preference, otherwise, use the browser preference
    const localStorageSelectedTheme = localStorage.getItem("selectedTheme");
    const browserPreference = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";
    return localStorageSelectedTheme || browserPreference;
  };

  const [theme, setTheme] = useState(getCurrentTheme() || "dark");

  // const handleParticlesInit = async (engine: Engine): Promise<void> => {
  //   await loadLinksPreset(engine);
  // };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`App-Wrapper App-Wrapper--${theme}`}>
        <div className="App">
        {/* <Particles
              options={{
                background: {
                  color: {
                    value: theme === "dark" ? "#292c35" : "#f2f2f5",
                  },
                },
                fpsLimit: 120,
                interactivity: {
                  events: {
                    onClick: {
                      enable: true,
                      mode: "push",
                    },
                    onHover: {
                      enable: true,
                      mode: "repulse",
                    },
                    resize: true,
                  },
                  modes: {
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 200,
                      duration: 0.4,
                    },
                  },
                },
                particles: {
                  color: {
                    value: theme === "dark" ? "#ffffff" : "#111",
                  },
                  links: {
                    color: theme === "dark" ? "#ffffff" : "#111",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                      default: "bounce",
                    },
                    random: false,
                    speed: 3,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 800,
                    },
                    value: 80,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    value: { min: 1, max: 5 },
                  },
                },
                detectRetina: true,
              }}
              init={handleParticlesInit}
            /> */}
          <Routes />
        </div>
      </div>
    </ThemeContext.Provider>
  );
};

export default App;
