import axios from "axios";

const getReverseGeoCode = ({ latitude, longitude }) => {
  return axios
    .get(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}`
    )
    .then(({ data }) => {
      return {
        data,
        error: false,
        error_message: undefined,
      };
    })
    .catch((error) => {
      console.error("handleReverseGeocode error: ", error);

      return {
        data: null,
        error: true,
        error_message: `getReverseGeoCode Error: ${error}`,
      };
    });
};

const getWeather = ({ latitude, longitude }) => {
  return axios
    .get("https://api.open-meteo.com/v1/forecast", {
      params: {
        current_weather: true,
        daily: ["sunrise", "sunset", "temperature_2m_max", "weathercode"],
        hourly: ["temperature_2m", "weathercode"],
        latitude,
        longitude,
        temperature_unit: "fahrenheit",
        timezone: "auto",
      },
    })
    .then(({ data }) => {
      return {
        data: {
          current_weather: data.current_weather,
          daily: {
            sunrise: data.daily.sunrise,
            sunset: data.daily.sunset,
            temperature: data.daily.temperature_2m_max.slice(0, 7),
            time: data.daily.time.slice(0, 7),
            weathercode: data.daily.weathercode,
          },
          hourly: {
            temperature: data.hourly.temperature_2m.slice(0, 25),
            time: data.hourly.time.slice(0, 25),
            weathercode: data.hourly.weathercode.slice(0, 25),
          },
        },
        error: false,
        error_message: undefined,
      };
    })
    .catch((error) => {
      console.error("handleGetWeather error: ", error);

      return {
        data: null,
        error: true,
        error_message: `getWeather Error: ${error}`,
      };
    });
};

export { getReverseGeoCode, getWeather };
