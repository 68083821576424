import { useContext } from "react";
import { ThemeContext } from "../../contexts/theme-context";
import "./LinkOut.scss";

interface Props {
  label: string;
  to: string;
}

const LinkOut = ({ label, to }: Props) => {
  const { theme } = useContext(ThemeContext);

  return (
    <a className={`Link--${theme}`} href={to} target="_blank" rel="noreferrer">
      {label}
    </a>
  );
};

export default LinkOut;
