import { useContext } from "react";

import { ThemeContext } from "../../../../../contexts/theme-context";

import "../AboutMe.scss";

const MyWhy = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <div className="AboutMe-SectionBreak-Wrapper">
        <hr
          className={`AboutMe-SectionBreak1 AboutMe-SectionBreak1--${theme}`}
        />
        <p>my "why"</p>
        <hr
          className={`AboutMe-SectionBreak2 AboutMe-SectionBreak2--${theme}`}
        />
      </div>

      <p>
        Creating and working with code simply suits me as a human. In my
        personal life, I love consuming "brain food," playing music, chess,
        combat sports, and demystifying things from various topics. It also
        makes me feel good inside when things are organized cleanly and clearly.
        I loved Legos as a kid (and as an adult &#128540;), so assembling
        something by following instructions feels like a sort of meditation for
        me, and that's a significant part of being a software engineer. I am
        naturally highly analytical, find comfort in routines, and value
        stability. I instinctively empathize with others' perspectives first,
        respect their thoughts and feelings, and genuinely want to help.
        However, I need to believe in what I am doing, which is why I have
        worked for EBSCO for as long as I have. They care about providing
        relevant and reliable information from trustworthy sources to people
        across the world. My goal is to make a meaningful impact on the world
        around me, and coding is one tool I have to achieve that.
      </p>
    </>
  );
};

export default MyWhy;
