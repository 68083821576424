import { TranslateWeatherCodeToIcon } from "./WeatherInterpretationCodes";
import "./Weather.scss";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

const WeatherIcon = ({
  code,
  size,
  sunIsUp
}: {
  code?: number;
  size: SizeProp;
  sunIsUp: boolean;
}) => {
  return (
    <div className="WeatherIcon">
      {TranslateWeatherCodeToIcon({ code, size, sunIsUp })}
    </div>
  );
};
export default WeatherIcon;
