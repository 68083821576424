import "./TotalView.scss";

interface Props {
  theme: string;
  total?: string;
}

const TotalView = ({ theme, total }: Props) => {
  const printTotal = (total: string) => {
    const isMoreThan10Characters = total?.length > 10;
    const totalToShow = isMoreThan10Characters ? total?.slice(0, 12) : total;
    const ellipsis = isMoreThan10Characters ? "..." : "";

    return `${totalToShow}${ellipsis}`;
  };

  return (
    <div className="Total Total-Wrapper">
      <p className={`Total--${theme}`}>{total && printTotal(total)}</p>
    </div>
  );
};

export default TotalView;
