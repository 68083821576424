

const songs = [
  {
    id: 1,
    author: "Achmad Satria",
    category: "Pickup Music",
    date: "May 16, 2021",
    isCover: true,
    title: "Dream",
    length: "1min 1sec",
    url: "https://vimeo.com/865327988?share=copy",
  },
  {
    id: 2,
    author: "Achmad Satria",
    category: "Pickup Music",
    date: "July 21, 2020",
    isCover: true,
    title: "Evening",
    length: "37sec",
    url: "https://vimeo.com/865328407?share=copy",
  },
  {
    id: 3,
    author: "Achmad Satria",
    category: "Pickup Music",
    date: "May 20, 2022",
    isCover: true,
    title: "Spring Leaves",
    length: "50sec",
    url: "https://vimeo.com/865329348?share=copy",
  },
  {
    id: 4,
    author: "Achmad Satria",
    category: "Pickup Music",
    date: "October 21, 2020",
    isCover: true,
    title: "Storm",
    length: "38sec",
    url: "https://vimeo.com/865330933?share=copy",
  },
  {
    id: 5,
    author: "Achmad Satria",
    category: "Pickup Music",
    date: "January 21, 2023",
    isCover: true,
    title: "Sunny Days",
    length: "34sec",
    url: "https://vimeo.com/865332426?share=copy",
  },
  {
    id: 6,
    author: "Achmad Satria",
    category: "Pickup Music",
    date: "June 2, 2023",
    isCover: true,
    title: "Try It",
    length: "35sec",
    url: "https://vimeo.com/865333957?share=copy",
  },
  {
    id: 7,
    author: "Achmad Satria",
    category: "Pickup Music",
    date: "November 9, 2021",
    isCover: true,
    title: "Untitled 4",
    length: "47sec",
    url: "https://vimeo.com/865334190?share=copy",
  },
  {
    id: 8,
    author: "Ashton Sellars",
    category: "Pickup Music",
    date: "July 12, 2020",
    isCover: true,
    title: "Close Enough To Hurt",
    length: "46sec",
    url: "https://vimeo.com/865335927?share=copy",
  },
  {
    id: 9,
    author: "Beau Diakowicz",
    category: "Pickup Music",
    date: "March 5, 2021",
    isCover: true,
    title: "Foam",
    length: "1min 17sec",
    url: "https://vimeo.com/865336603?share=copy",
  },
  {
    id: 10,
    author: "Beau Diakowicz",
    category: "Pickup Music",
    date: "January 13, 2023",
    isCover: true,
    title: "Lofi",
    length: "49sec",
    url: "https://vimeo.com/865339629?share=copy",
  },
  {
    id: 11,
    author: "Beau Diakowicz",
    category: "Pickup Music",
    date: "February 6, 2021",
    isCover: true,
    title: "Yellow",
    length: "43sec",
    url: "https://vimeo.com/865341346?share=copy",
  },
  {
    id: 12,
    author: "Eric Assarsson",
    category: "Pickup Music",
    date: "April 10, 2021",
    isCover: true,
    title: "I Miss You",
    length: "52sec",
    url: "https://vimeo.com/865343129?share=copy",
  },
  {
    id: 13,
    author: "Florian Naegeli",
    category: "Pickup Music",
    date: "July 17, 2021",
    isCover: true,
    title: "Nachtlicht",
    length: "24sec",
    url: "https://vimeo.com/865345046?share=copy",
  },
  {
    id: 14,
    author: "Leticia Filizzola",
    category: "Pickup Music",
    date: "July 8, 2021",
    isCover: true,
    title: "Bloom",
    length: "57sec",
    url: "https://vimeo.com/865345213?share=copy",
  },
  {
    id: 15,
    author: "Leticia Filizzola",
    category: "Pickup Music",
    date: "August 4, 2023",
    isCover: true,
    title: "Replay",
    length: "1min",
    url: "https://vimeo.com/865346458?share=copy",
  },
  {
    id: 16,
    author: "Lyle Brewer",
    category: "Pickup Music",
    date: "August 27, 2021",
    isCover: true,
    title: "You Were Just A Kid",
    length: "57sec",
    url: "https://vimeo.com/865346709?share=copy",
  },
  {
    id: 17,
    author: "Nicklas Myre",
    category: "Pickup Music",
    date: "Apirl 10, 2021",
    isCover: true,
    title: "Elm",
    length: "58sec",
    url: "https://vimeo.com/865347936?share=copy",
  },
  {
    id: 18,
    author: "Nicklas Myre",
    category: "Pickup Music",
    date: "December 22, 2021",
    isCover: true,
    title: "Jingle Bells",
    length: "45sec",
    url: "https://vimeo.com/865349364?share=copy",
  },
  {
    id: 19,
    author: "Nicklas Myre",
    category: "Pickup Music",
    date: "July 12, 2022",
    isCover: true,
    title: "Juniper 2",
    length: "58sec",
    url: "https://vimeo.com/865361642?share=copy",
  },
  {
    id: 20,
    author: "Nicklas Myre",
    category: "Pickup Music",
    date: "July 12, 2022",
    isCover: true,
    title: "Juniper",
    length: "1min 3sec",
    url: "https://vimeo.com/865363105?share=copy",
  },
  {
    id: 21,
    author: "Rodigo Gouvei",
    category: "Pickup Music",
    date: "July 18, 2023",
    isCover: true,
    title: "Day By Day",
    length: "1min 9sec",
    url: "https://vimeo.com/865364403?share=copy",
  },
  {
    id: 22,
    author: "Talita Yera",
    category: "Pickup Music",
    date: "October 17, 2020",
    isCover: true,
    title: "Always With Me",
    length: "47sec",
    url: "https://vimeo.com/865365456?share=copy",
  },
  {
    id: 23,
    author: "Talita Yera",
    category: "Pickup Music",
    date: "January 17, 2021",
    isCover: true,
    title: "Connected",
    length: "1min",
    url: "https://vimeo.com/865366468?share=copy",
  },
  {
    id: 24,
    author: "Talita Yera",
    category: "Pickup Music",
    date: "November 21, 2021",
    isCover: true,
    title: "Leve",
    length: "1min",
    url: "https://vimeo.com/865367792?share=copy",
  },
  {
    id: 25,
    author: "Houston Breedlove",
    category: "JTIWC",
    date: "May 16, 2021",
    isCover: false,
    title: "JTIWC 120",
    length: "50sec",
    url: "https://vimeo.com/865310943?share=copy",
  },
  {
    id: 26,
    author: "Houston Breedlove",
    category: "JTIWC",
    date: "May 20, 2021",
    isCover: false,
    title: "JTIWC 121",
    length: "51sec",
    url: "https://vimeo.com/865312296?share=copy",
  },
  {
    id: 27,
    author: "Houston Breedlove",
    category: "JTIWC",
    date: "June 5, 2021",
    isCover: false,
    title: "JTIWC 123",
    length: "1min 6sec",
    url: "https://vimeo.com/865313669?share=copy",
  },
  {
    id: 28,
    author: "Houston Breedlove",
    category: "JTIWC",
    date: "June 12, 2021",
    isCover: false,
    title: "JTIWC 124",
    length: "1min 29sec",
    url: "https://vimeo.com/865315569?share=copy",
  },
  {
    id: 29,
    author: "Houston Breedlove",
    category: "JTIWC",
    date: "June 17, 2021",
    isCover: false,
    title: "JTIWC 127",
    length: "1min 2sec",
    url: "https://vimeo.com/865317777?share=copy",
  },
  {
    id: 30,
    author: "Houston Breedlove",
    category: "JTIWC",
    date: "August 28, 2021",
    isCover: false,
    title: "JTIWC 128",
    length: "54sec",
    url: "https://vimeo.com/865319604?share=copy",
  },
  {
    id: 31,
    author: "Houston Breedlove",
    category: "JTIWC",
    date: "August 29, 2021",
    isCover: false,
    title: "JTIWC 129",
    length: "50ec",
    url: "https://vimeo.com/865319955?share=copy",
  },
  {
    id: 32,
    author: "Houston Breedlove",
    category: "JTIWC",
    date: "November 7, 2021",
    isCover: false,
    title: "JTIWC 132",
    length: "51sec",
    url: "https://vimeo.com/865321141?share=copy",
  },
  {
    id: 33,
    author: "Houston Breedlove",
    category: "JTIWC",
    date: "Upload date unknown",
    isCover: false,
    title: "JTIWC -1",
    length: "1min",
    url: "https://vimeo.com/865322578?share=copy",
  },
  {
    id: 34,
    author: "Houston Breedlove",
    category: "JTIWC",
    date: "Upload date unknown",
    isCover: false,
    title: "JTIWC -2",
    length: "38sec",
    url: "https://vimeo.com/865324169?share=copy",
  }
];

export default songs;
