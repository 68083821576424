import { ReactElement } from "react";
import "./SubNav.scss";

interface Props {
  label: string | ReactElement;
}

const SubNav = ({ label }: Props) => {
  return (
    <div className="SubNav">
      <div className="SubNav-Label">{label}</div>
    </div>
  );
};

export default SubNav;
