import { useEffect, useState } from "react";
import WeatherIcon from "../../../../WeatherIcon";

import "./CurrentWeather.scss";

interface ICurrentWeather {
  temperature: number;
  weathercode: number;
}

interface IDaily {
  sunrise?: string | undefined;
  sunset?: string | undefined;
}

interface IWeather {
  current_weather: ICurrentWeather;
  daily: IDaily;
}

interface Props {
  weather?: IWeather;
}

const WeatherFullPageView = ({ weather }: Props) => {
  const [isSunUp, setIsSunUp] = useState(false);

  useEffect(() => {
    const sunHasRisen = () => {
      const currentTime = new Date().toISOString();

      if (
        weather?.daily.sunrise &&
        weather?.daily.sunset &&
        currentTime > new Date(weather?.daily.sunrise[0]).toISOString() &&
        currentTime < new Date(weather?.daily.sunset[0]).toISOString()
      )
        return true;

      return false;
    };

    setIsSunUp(sunHasRisen());
  }, [weather]);

  return (
    <section className="CurrentWeather">
      {weather ? (
        <>
          <WeatherIcon
            code={weather.current_weather.weathercode}
            size="8x"
            sunIsUp={isSunUp}
          />
          <p style={{ fontSize: "7rem", marginInlineStart: "1rem" }}>
            {Math.round(weather.current_weather.temperature)}°F
          </p>
        </>
      ) : (
        <p style={{ fontSize: "7rem", marginInlineStart: "1rem" }}>--</p>
      )}
    </section>
  );
};

export default WeatherFullPageView;
