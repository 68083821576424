import { useState } from "react";
import ProblemToSolve from "./ProblemToSolve";
import TotalView from "./TotalView";

import "./Calculator.scss";
import NavBar from "../../../NavBar";
import SubNav from "../../../NavBar/SubNav";
import Footer from "../../../Footer";
import { Link } from "react-router-dom";

interface Props {
  theme: string;
}

const CalculatorV2 = ({ theme }: Props) => {
  const [isSqrt, setIsSqrt] = useState(false);
  const [operation, setOperation] = useState("");
  const [total, setTotal] = useState("");
  const [stateValue1, setStateValue1] = useState("");
  const [stateValue2, setStateValue2] = useState("");

  const add = (value1: number, val2: number) => value1 + val2;
  const divide = (value1: number, val2: number) => value1 / val2;
  const multiply = (value1: number, val2: number) => value1 * val2;
  const subtract = (value1: number, val2: number) => value1 - val2;

  const handleInvert = (total: string) => {
    setTotal(`${Number(total) * -1}`);
  };

  const handleReset = () => {
    setStateValue1("");
    setOperation("");
    setStateValue2("");
    setTotal("");
    setIsSqrt(false);
  };

  const handleSetOperation = (operation: string, total: string) => {
    if (total) {
      setIsSqrt(false);
      setStateValue1(total);
      setOperation(operation);
      setStateValue2("");
    } else {
      setOperation(operation);
    }
  };

  const handleSqrt = (
    operation: string,
    total: string,
    value1: string,
    value2: string
  ) => {
    const getTotal = () => {
      switch (operation) {
        case "+":
          return `${add(Number(total ? total : value1), Number(value2))}`;
        case "-":
          return `${subtract(Number(total ? total : value1), Number(value2))}`;
        case "x":
          return `${multiply(Number(total ? total : value1), Number(value2))}`;
        case "/":
          return `${divide(Number(total ? total : value1), Number(value2))}`;
        case "√":
          handleSqrt(operation, total, value1, value2);
      }
    };

    if (value1 && !stateValue2) {
      return setTotal(`${Math.sqrt(Number(value1))}`);
    }

    if (value1 && operation && !stateValue2) {
      return setTotal(`${Math.sqrt(Number(value1))}`);
    }

    if (value1 && operation && stateValue2) {
      const toBeSqrd = getTotal();
      return setTotal(`${Math.sqrt(Number(toBeSqrd))}`);
    }
  };

  const handleTotal = (
    isSqrt: boolean,
    operation: string,
    total: string,
    value1: string,
    value2: string
  ) => {
    if (isSqrt) {
      handleSqrt(operation, total, value1, value2);
      return;
    }

    switch (operation) {
      case "+":
        setTotal(`${add(Number(total ? total : value1), Number(value2))}`);
        return;
      case "-":
        setTotal(`${subtract(Number(total ? total : value1), Number(value2))}`);
        return;
      case "x":
        setTotal(`${multiply(Number(total ? total : value1), Number(value2))}`);
        return;
      case "/":
        setTotal(`${divide(Number(total ? total : value1), Number(value2))}`);
        return;
    }
  };

  const handleValuePress = (
    operation: string,
    pressedValue: string,
    value1: string,
    value2: string
  ) => {
    if (operation === "√") {
      if (value1 && operation && value2 && total) {
        setIsSqrt(false);
        setStateValue1(pressedValue);
        setOperation("");
        setStateValue2("");
      }

      if (value1 && operation && !value2) {
        setStateValue2(pressedValue);
      }
    } else {
      const newState1 = value1.length < 15 ? `${value1}${pressedValue}` : value1;
      const newState2 = value2.length < 15 ? `${value2}${pressedValue}` : value2;
      operation ? setStateValue2(newState2) : setStateValue1(newState1);
    }
  };

  const getSubNavLabel = () => {
    return (
      <div className="CalculatorV2-SubNav">
        <Link className={`CalculatorV2-Link--${theme}`} to="/other/calculator/version-select">calculator</Link>
        <p className="CalculatorV2-SubNav-Slash">/</p>
        <p className="CalculatorV2-SubNav-Version">v2</p>
      </div>
    );
  };

  return (
    <>
      <NavBar />
      <SubNav label={getSubNavLabel()} />
      <div className={`CalculatorV2-Center CalculatorV2-Center--${theme}`}>
        <div className="CalculatorV2">
          {/* <div className="CalculatorV2-Padding"> */}
            <div className={`CalculatorV2-Wrapper CalculatorV2-Wrapper--${theme}`}>
              <div className={`CalculatorV2-Feedback CalculatorV2-Feedback--${theme}`}>
                <ProblemToSolve
                  operation={operation}
                  showEqualSign={!!total}
                  showSquareRoot={isSqrt}
                  theme={theme}
                  value1={stateValue1}
                  value2={stateValue2}
                />
                <TotalView theme={theme} total={total} />
              </div>
              <div className="CalculatorV2-Row-Wrapper">
                <div className="CalculatorV2-Row">
                  <button onClick={() => handleReset()}>AC</button>
                  <button disabled={!total} onClick={() => handleInvert(total)}>
                    +/-
                  </button>
                  <button
                    className={isSqrt ? `CalculatorV2-isSqrt--${theme}` : ""}
                    onClick={() => setIsSqrt(!isSqrt)}
                  >
                    √
                  </button>
                  <button onClick={() => handleSetOperation("x", total)}>
                    x
                  </button>
                </div>
                <div className="CalculatorV2-Row">
                  <button
                    onClick={() =>
                      handleValuePress(operation, "7", stateValue1, stateValue2)
                    }
                  >
                    7
                  </button>
                  <button
                    onClick={() =>
                      handleValuePress(operation, "8", stateValue1, stateValue2)
                    }
                  >
                    8
                  </button>
                  <button
                    onClick={() =>
                      handleValuePress(operation, "9", stateValue1, stateValue2)
                    }
                  >
                    9
                  </button>
                  <button onClick={() => handleSetOperation("/", total)}>
                    /
                  </button>
                </div>
                <div className="CalculatorV2-Row">
                  <button
                    onClick={() =>
                      handleValuePress(operation, "4", stateValue1, stateValue2)
                    }
                  >
                    4
                  </button>
                  <button
                    onClick={() =>
                      handleValuePress(operation, "5", stateValue1, stateValue2)
                    }
                  >
                    5
                  </button>
                  <button
                    onClick={() =>
                      handleValuePress(operation, "6", stateValue1, stateValue2)
                    }
                  >
                    6
                  </button>
                  <button onClick={() => handleSetOperation("-", total)}>
                    -
                  </button>
                </div>
                <div className="CalculatorV2-Row">
                  <button
                    onClick={() =>
                      handleValuePress(operation, "1", stateValue1, stateValue2)
                    }
                  >
                    1
                  </button>
                  <button
                    onClick={() =>
                      handleValuePress(operation, "2", stateValue1, stateValue2)
                    }
                  >
                    2
                  </button>
                  <button
                    onClick={() =>
                      handleValuePress(operation, "3", stateValue1, stateValue2)
                    }
                  >
                    3
                  </button>
                  <button onClick={() => handleSetOperation("+", total)}>
                    +
                  </button>
                </div>
                <div className="CalculatorV2-Row">
                  <button
                    onClick={() =>
                      handleValuePress(operation, "0", stateValue1, stateValue2)
                    }
                  >
                    0
                  </button>
                  <button
                    onClick={() =>
                      handleValuePress(operation, ".", stateValue1, stateValue2)
                    }
                  >
                    .
                  </button>
                  <button></button>
                  <button
                    onClick={() =>
                      handleTotal(
                        isSqrt,
                        operation,
                        total,
                        stateValue1,
                        stateValue2
                      )
                    }
                  >
                    =
                  </button>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CalculatorV2;
