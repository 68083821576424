import { ThemeContext } from "../../contexts/theme-context";
import Footer from "../Footer";
import NavBar from "../NavBar";

import "./NotFound.scss";

interface Props {
  theme: string;
}

const NotFound = ({ theme }: Props) => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <>
        <NavBar />
        <div className={`NotFound-Center NotFound-Center--${theme}`}>
          <h1>404 Not Found</h1>
          <p>
            Hm... I'm not sure how you got here but... The page looking for
            doesn't exist &#129300;
          </p>
        </div>
        <Footer />
      </>
    )}
  </ThemeContext.Consumer>
);

export default NotFound;
