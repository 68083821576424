import { useEffect, useMemo, useState } from "react";

import WeatherIcon from "./WeatherIcon";
import { TranslateWeatherCodeToString } from "./WeatherInterpretationCodes";

import { NavLink } from "react-router-dom";
import getLocationAndWeather from "./GetLocationAndWeather";

import "./Weather.scss";

const Weather = () => {
  const raleighZipcode = "27613";
  const [location, setLocation] = useState(raleighZipcode); // setLocation to be used when I have functionality of user input
  const [weatherAndLocation, setWeatherAndLocation] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [translatedWeatherCode, setTranslatedWeatherCode] = useState("");
  const [isSunUp, setIsSunUp] = useState(false);

  const sunHasRisen = useMemo(() => {
    const currentTime = new Date().toISOString();

    if (
      weatherAndLocation?.daily?.sunrise &&
      weatherAndLocation?.daily?.sunset &&
      currentTime >
        new Date(weatherAndLocation?.daily.sunrise[0]).toISOString() &&
      currentTime < new Date(weatherAndLocation?.daily.sunset[0]).toISOString()
    )
      return true;

    return false;
  }, [weatherAndLocation]);

  useEffect(() => {
    setIsSunUp(sunHasRisen);
  }, [isSunUp, sunHasRisen, weatherAndLocation]);

  useEffect(() => {
    if (location) {
      setLoading(true);

      getLocationAndWeather("27613")
        .then((res) => {
          setWeatherAndLocation(res);

          const translatedWeatherCode = TranslateWeatherCodeToString(
            res.weather.data.current_weather.weathercode
          );
          setTranslatedWeatherCode(translatedWeatherCode);

          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          
          setError(true);
          setLoading(false);
        });
    }
  }, [location]);

  const getButtonLabel = useMemo(() => {
    const errorLabel =
      "Oh no! Something went wrong while fetching weather information.";
    const loadingLabel = "loading weather...";
    const locationLabel = weatherAndLocation?.location;
    const weatherTemperature =
      weatherAndLocation?.weather?.data?.current_weather?.temperature;

    if (loading) return loadingLabel;

    if (error) return errorLabel;

    return (
      <>
        <p>{`Weather in ${locationLabel} is ${
          weatherTemperature && `${Math.round(weatherTemperature)}°F and`
        } ${translatedWeatherCode}`}</p>
        <WeatherIcon
          code={
            weatherAndLocation?.weather?.data?.current_weather?.weather_code
          }
          sunIsUp={isSunUp}
        />
      </>
    );
  }, [error, isSunUp, loading, translatedWeatherCode, weatherAndLocation]);

  return (
    <div className="Weather">
      <NavLink className="Weather-Link" to="/weather">
        {getButtonLabel}
      </NavLink>
    </div>
  );
};

export default Weather;
