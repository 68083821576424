import { useContext } from "react";
import LinkOut from "../../../../LinkOut";

import "../AboutMe.scss";
import { ThemeContext } from "../../../../../contexts/theme-context";

const BriefOverview = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <div className="AboutMe-SectionBreak-Wrapper">
        <hr
          className={`AboutMe-SectionBreak1 AboutMe-SectionBreak1--${theme}`}
        />
        <p>brief overview</p>
        <hr
          className={`AboutMe-SectionBreak2 AboutMe-SectionBreak2--${theme}`}
        />
      </div>
      <p>
        I'm primarily a JavaScript developer. I originally learned to code
        through{" "}
        <LinkOut
          label="The Coding Boot Camp at UNC-Chapel Hill"
          to="https://bootcamp.unc.edu/coding/"
        />
        . Halfway through the 6-month course, I secured an internship at a
        company called inMotionNow, which was later aquired by{" "}
        <LinkOut label="Lytho" to="https://www.lytho.com/" />. After completing
        my course, I was hired full time, successfully entering the world of
        software engineering.
      </p>
      <p>
        In an effort to further my growth as a software engineer, I secured a
        software engineering position at{" "}
        <LinkOut label="EBSCO" to="https://www.ebsco.com/" />, where I work
        professionally. Today, I hold the position of Senior Software Engineer
        and Technical Team Lead of the{" "}
        <LinkOut
          label="EBSCO Mobile App"
          to="https://www.ebsco.com/products/ebsco-mobile-app-research-anytime-anywhere"
        />
        . I take great pride in contributing to a project that provides reliable
        information from trustworthy sources to users across the globe, spanning
        various industries.
      </p>
    </>
  );
};

export default BriefOverview;
