import { useContext } from "react";
import { ThemeContext } from "../../contexts/theme-context";
import "./ProgressBar.scss";

interface Props {
  percent: number;
}

const ProgressBar = ({ percent }: Props) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={`ProgressBar-Container ProgressBar-Container--${theme}`}>
      <div
        className={`ProgressBar ProgressBar--${theme}`}
        style={{ width: `${percent}%` }}
      />
    </div>
  );
};

export default ProgressBar;
