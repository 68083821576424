// import Calculator from "./Calculator";
import Footer from "../Footer";
import NavBar from "../NavBar";

import "./Other.scss";
import { Link } from "react-router-dom";
import SubNav from "../NavBar/SubNav";

interface Props {
  theme: string;
}

const Other = ({ theme }: Props) => (
  <>
    <NavBar />
    <div className={`Other-Center Other-Center--${theme}`}>
      {/* <Calculator theme={theme} /> */}
      <Link
        to="/other/calculator/version-select"
        className={`Other-Button Other-Button--${theme}`}
      >
        calculator
      </Link>
      <Link
        to="/weather"
        className={`Other-Button Other-Button--${theme}`}
      >
        weather
      </Link>
    </div>
    <Footer />
  </>
);

export default Other;
