import { useEffect, useState } from "react";

import { labelToTimeTranslations } from "../../WeatherFullPageView";
import WeatherIcon from "../../../../WeatherIcon";

import "./HourlyItem.scss";

interface Props {
  label: string;
  sunrise: string;
  sunset: string;
  temperature: number;
  weatherCode: number;
}

const HourlyItem = ({
  label,
  sunrise,
  sunset,
  temperature,
  weatherCode,
}: Props) => {
  const [isSunUp, setIsSunUp] = useState(false);

  useEffect(() => {
    const sunHasRisen = () => {
      const obj = labelToTimeTranslations.find((item) => item.label === label);
      const time = obj ? new Date(obj?.time) : undefined;

      if (
        time &&
        time.getHours() > new Date(sunrise).getHours() &&
        time &&
        time.getHours() < new Date(sunset).getHours()
      )
        return true;

      return false;
    };

    setIsSunUp(sunHasRisen());
  }, []);

  return (
    <li className="HourlyItem">
      <WeatherIcon code={weatherCode} size="xl" sunIsUp={isSunUp} />
      <div className="HourlyItem-TextWrapper">
        <p className="HourlyItem-Time">{label}</p>

        <p className="HourlyItem-Temperature">
          {temperature ? `${Math.round(temperature)}°F` : "--"}
        </p>
      </div>
    </li>
  );
};

export default HourlyItem;
