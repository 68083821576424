import { useContext } from "react";
import { NavLink } from "react-router-dom";

import { ThemeContext } from "../../contexts/theme-context";
import ThemeToggle from "../ThemeToggle";

import "./NavBar.scss";


const NavBar = () => {
  const { theme } = useContext(ThemeContext);

  const getClassNames = (isActive: boolean) => {
    const classNames = {
      darkLink: "NavBar-Link--dark",
      link: "NavBar-Link",
      lightLink: "NavBar-Link--light",
    };

    return theme === "dark"
      ? `${classNames.link} ${classNames.darkLink} ${
          isActive && `${classNames.darkLink}--isActive`
        }`
      : `${classNames.link} ${classNames.lightLink} ${
          isActive && `${classNames.lightLink}--isActive`
        }`;
  };

  return (
    <nav className={`NavBar-Nav NavBar-Nav--${theme}`}>
      <ul className="NavBar-UnorderedList">
        <li>
          <NavLink className={({ isActive }) => getClassNames(isActive)} to="/">
            home
          </NavLink>
        </li>
        <li aria-hidden className="NavBar-Slash">/</li>
        <>
          <li>
            <NavLink
              className={({ isActive }) => getClassNames(isActive)}
              to="/code"
            >
              code
            </NavLink>
          </li>
          <li aria-hidden className="NavBar-Slash">/</li>
          <li>
            <NavLink
              className={({ isActive }) => getClassNames(isActive)}
              to="/guitar"
            >
              guitar
            </NavLink>
          </li>
          <li aria-hidden className="NavBar-Slash">/</li>
        </>
        <li>
          <NavLink
            className={({ isActive }) => getClassNames(isActive)}
            to="/other"
          >
            other
          </NavLink>
        </li>
      </ul>
      <ThemeToggle />
    </nav>
  );
};

export default NavBar;
