import Footer from "../Footer";
import { NavLink } from "react-router-dom";
import Userfront, { LoginForm } from "@userfront/toolkit";

import "./Guitar.scss";
import InstagramIcon from "./components/InstagramIcon";
import NavBar from "../NavBar";

interface Props {
  theme: string;
}

const Guitar = ({ theme }: Props) => {
  return (
    <>
      <NavBar />
      <div className={`Guitar-ButtonView`}>
        <NavLink
          to={"/guitar/video-player"}
          className={`Guitar-ButtonView-Button Guitar-ButtonView-Button--${theme}`}
        >
          Instagram videos from before this site
        </NavLink>
      </div>
      <InstagramIcon theme={theme} style={{ top: "3.25rem" }} />
      <button
        className={`Guitar-Logout Guitar-Logout--${theme}`}
        onClick={() => Userfront.logout()}
      >
        Logout
      </button>
      <Footer />
    </>
  );
};

export default Guitar;
