import React, { useEffect, useState } from "react";

import "./SongSelect.scss";
import songList from "../songs";

interface Props {
  onSongSelect: (song: Song) => void;
  selectedSongId?: number;
  theme: string;
}

interface Song {
  id: number;
  url: string;
}

const SongSelect = ({ onSongSelect, selectedSongId, theme }: Props) => {
  const [search, setSearch] = useState("");
  const [songs, setSongs] = useState({ data: songList, filtered: songList });

  useEffect(() => {
    var newSongList = search?.length
      ? songs.data.filter((song) => {
          if (
            song.title.toLowerCase().includes(search.toLowerCase()) ||
            song.author.toLowerCase().includes(search.toLowerCase())
          ) {
            return song;
          }
          return false;
        })
      : songList;

    setSongs({ data: songList, filtered: newSongList });
    newSongList = [];
  }, [search]);

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e?.currentTarget?.value);
  };

  return (
    <div className={`SongSelect`}>
      <div className={`SongSelect-Filter-Wrapper`}>
        <input
          className={`SongSelect-Filter-Search`}
          onChange={handleSearchChange}
          placeholder="search"
          type="search"
          value={search}
        />
      </div>
      <div className={`SongSelect-List`}>
        <ul>
          {songs.filtered.map((song) => (
            <li className={`SongSelect-Song`} key={song.id}>
              <button
                onClick={() => onSongSelect(song)}
                className={`SongSelect-Song-Button SongSelect-Song-Button--${theme} ${
                  selectedSongId === song.id &&
                  `SongSelect-Song-Button--isActive SongSelect-Song-Button--isActive--${theme}`
                }`}
              >
                <div>
                  <p>{song.title}</p>
                  <p className={`SongSelect-Song-Button--secondaryText`}>
                    {song.author} &#x2022; {song.category}
                  </p>
                </div>
                <div style={{ textAlign: "right" }}>
                  <p>{song.length}</p>
                  <p className={`SongSelect-Song-Button--secondaryText`}>
                    {song.date}
                  </p>
                </div>
              </button>
            </li>
          ))}
           
        </ul>
      </div>
    </div>
  );
};

export default SongSelect;
