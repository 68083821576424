import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import songs from "./songs";
import "./GuitarVideoPlayer.scss";
import Footer from "../../../Footer";
import NavBar from "../../../NavBar";
import InstagramIcon from "../../components/InstagramIcon";
import SongSelect from "./SongSelect";
import SubNav from "../../../NavBar/SubNav";

interface Props {
  theme: string;
}

interface Song {
  id: number;
  url: string;
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

const GuitarVideoPlayer = ({ theme }: Props) => {
  const [selectedSong, setSelectedSong] = useState<Song>(songs[0]);

  const { height, width } = useWindowDimensions();

  const getHeight = () => {
    if (height < 400) return "215%";
    if (height < 500) return "200%";
    if (height < 600) return "150%";
    if (height < 700) return "115%";
    // if (height < 800) return 675;
    return "100%";
  };

  const getWidth = () => {
    if (width < 400) return 275;
    if (width < 500) return 375;
    if (width < 600) return 475;
    if (width < 700) return 575;
    if (width < 800) return 675;
    return "640px";
  };

  const players = songs.map((song) => {
    return (
      <ReactPlayer
        controls={true}
        progressInterval={50}
        url={song.url}
        width={getWidth()}
      />
    );
  });

  const handleSongPress = (song: { id: number; url: string }) => {
    setSelectedSong(song);
  };

  const getPlayerFor = (songId: number) => {
    const id = songId - 1;
    return players[id];
  };

  return (
    <>
      <NavBar />
      <SubNav label="Instagram videos from before this site" />
      <div
        className={`GuitarVideoPlayer-Center GuitarVideoPlayer-Center--${theme}`}
      >
        <div
          className={`GuitarVideoPlayer-ContentWrapper`}
          style={{ height: getHeight() }}
        >
          <div
            className={`GuitarVideoPlayer-Video GuitarVideoPlayer-Video--${theme}`}
          >
            <div
              className={`GuitarVideoPlayer-Video-Space GuitarVideoPlayer-Video-Space--${theme}`}
              style={{ position: "relative", height: getHeight(), width: getWidth() }}
            >
              {getPlayerFor(selectedSong.id)}
            </div>
          </div>
          <SongSelect
            onSongSelect={handleSongPress}
            selectedSongId={selectedSong?.id}
            theme={theme}
          />
        </div>
      </div>
      <div className={`GuitarVideoPlayer-FooterBorder`}>
        <Footer />
      </div>
    </>
  );
};

export default GuitarVideoPlayer;
